.container2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider {
  width: 100%;
  max-width: 800px;
}

.slider-img {
  height: 416px;
}

.slider-img img {
  height: 416px;
  object-fit: cover;
  width: 100%;
}

.slider-header {
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

.slider-header h1 {
  font-size: 40px;
  color: #000929b3;
  font-family: 'Unbounded';
  font-weight: 500;
  margin-bottom: 30px;
  word-break: auto-phrase;
  text-align: center;
}

@media (max-width: 320px) {
  .slider-img {
    height: 300px;
  }
  .slider-img img {
    height: 300px;
  }
}

@media (max-width: 414px) {
  .slider-img {
    margin-left: 0;
    height: 350px;
  }
  .slider-img img {
    height: 350px;
  }
}

@media (max-width: 390px) {
  .slider-img {
    height: 375px;
  }
  .slider-img img {
    height: 375px;
  }
}

@media (max-width: 467px) {
  .slider {
    max-width: 100%;
  }

  .slider-img {
    margin-left: 0;
    margin-top: 20px;
    width: 100%;
    height: auto;
  }

  .slider-img img {
    height: auto;
    max-width: 100%;
    object-fit: contain;
  }

  .slider-text {
    word-break: break-all;
    font-size: 22px;
    text-align: center;
  }
}

@media (max-width: 1024px) {
  .slider {
    max-width: 90%;
  }

  .slider-img {
    height: 400px;
  }

  .slider-img img {
    height: 400px;
    object-fit: cover;
    width: 100%;
  }

  .slider-header h1 {
    font-size: 36px;
    margin-bottom: 25px;
  }

  .slider-text {
    font-size: 24px;
    text-align: center;
    padding: 10px 20px;
  }
}

@media (max-width: 768px) {
  .slider {
    max-width: 100%;
  }

  .slider-img {
    height: 350px;
  }

  .slider-img img {
    height: 350px;
  }

  .slider-header h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }

  .slider-text {
    font-size: 20px;
    text-align: center;
  }
}
