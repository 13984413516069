.xizmat-btn {
  background-color: #feb640;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 20px;
  font-weight: 500;
  font-family: "Unbounded";
  width: 190px;
  height: 50px;
  margin-top: 15px;
}

.ximat {
  padding: 60px 0;
  width: 100%;
  color: #020c31;
  font-family: "Unbounded";
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  margin-bottom: 16px;
}

.ximat h1 {
  font-size: 36px;
  color: rgba(0, 9, 41, 0.7019607843);
  margin-bottom: 40px;
}

.xizmat-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
  justify-items: center;
  align-items: start;
}

.xizmat-cards div {
  background: #f3f6f6;
  width: 460px;
  height: 440px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.xizmat-cards img {
  width: 100%;
  margin-bottom: 20px;
}

.xizmat-cards h3 {
  font-size: 30px;
  color: #2c788d;
  width: 400px;
  font-family: 'Unbounded';
  font-weight: 700;
  line-height: normal;
  margin-bottom: 30px;
  margin: 0 auto;
  text-align: center;
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

.xizmat-btn:hover {
  background-color: #e4be31;
}

@media (max-width: 768px) {
  .ximat {
    font-size: 36px;
    line-height: 48px;
  }

  .xizmat-cards {
    width: 100%;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .xizmat-cards div {
    width: 100%;
    height: auto;
  }

  .xizmat-cards h3 {
    width: 100%;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .xizmat-btn {
    padding: 10px 20px;
    font-size: 14px;
    margin-bottom: 20px;
  }
}

@media (max-width: 467px) {
  .ximat {
    width: 100%;
    font-size: 28px;
    line-height: 36px;
  }

  .ximat h1 {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .xizmat-cards {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .xizmat-cards div {
    width: 100%;
    height: auto;
    box-shadow: none;
  }

  .xizmat-cards h3 {
    width: 100%;
    font-size: 20px;
  margin-bottom: 15px;
  }

  .xizmat-btn {
    padding: 8px 16px;
    font-size: 15px;
    max-width: 150px;
    height: 40px;
  }

  .xizmat-cards img {
    margin-bottom: 15px;
  }
}
