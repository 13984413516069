.contact-info {
  width: 100%;
  height: 550px;
  background-repeat: no-repeat;
  background: radial-gradient(333.17% 80.94% at 51.32% 69.21%, #04A2BA 0%, rgba(2, 227, 214, .2) 100%);
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 15px;
  margin-top: 50px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



.contact-img {
  background-image: url("../images/carpet2.png");
  width: 515px;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  height: 400px;
  right: -45px;
}

.form-div {
  margin-left: 150px;

  input {
    width: 416px;
    height: 50px;
    border: none;
    outline: none;
    border-radius: 8px;
    margin-top: 0px;
    color: black;
    background: rgba(128, 128, 128, 0.199);
    margin-left: 18px;
    padding-left: 15px;
    
  }
  
  h2 {
    color: #242825;
    font-family: Unbounded;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    padding-top: 20px;
    padding-left: 20px;
  }

  button {
    width: 416px;
    height: 50px;
    background-color: #242825;
    color: white;
    font-family: Unbounded;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 5px;
    margin-left: 18px;
    margin-top: 60px;
  }
}

.input-div{
  height: 150px;
  span{
    margin-left: 18px;
  }
}
.error-text {
  position: absolute;
  bottom: -20px;
  left: 0;
  color: red;
  font-size: 12px;
  visibility: hidden; 
  opacity: 0; 
  transition: opacity 0.2s ease-in-out; 
}

.error-text.visible {
  visibility: visible;
  opacity: 1; 
}

.form-group {
  position: relative;
  margin-bottom: 20px; 
}

.input-error {
  border: 2px solid rgba(255, 0, 0, 0.852);
  background-color: #ffe6e6;
}

.error-text {
  position: absolute; 
  bottom: -20px; 
  left: 0;
  color: rgba(255, 0, 0, 0.857);
  font-size: 12px;
  visibility: hidden; 
}

.input-error + .error-text {
  visibility: visible; 
  font-family: "Unbounded";
  
}


.form-group {
  margin-top: 15px;

  .in2{
    margin-top: 20px;
  }
}

.form-container {
  border-radius: 7px;
  background: white;
  width: 450px;
  height: 400px;
}


@media (max-width: 768px) {
  .contact-info {
    flex-direction: column;
    height: auto;
    padding: 20px;
    margin-top: 30px;
  }



  .contact-img {
    display: none;
    width: 300px;
    height: 250px;
    right: 0;
    position: static; 
    margin: 20px auto;
  }

  .form-div {
    margin-left: 0;
    input {
      width: 100%; 
      margin-left: 0;
    }
    
    button {
      width: 100%;
      margin-left: 0;
    }

    h2 {
      font-size: 28px;
      padding-left: 0;
      text-align: center;
    }
  }

  .form-container {
    width: 100%; 
    height: auto;
    padding: 20px;
  }
  .input-div{
    span{
      margin-left: 0px;
    }
  }
}

/* Responsive Design for Mobile (max-width: 467px) */
@media (max-width: 467px) {
  .contact-info {
    height: auto;
    padding: 15px;
    flex-direction: column;
  }

  .input-div{
    span{
      margin-left: 0px;
    }
  }





  .contact-img {
    display: none;
    width: 200px;
    height: 200px;
    margin: 10px auto;
  }

  .form-div {
    margin-left: 0;
    input {
      width: 100%; 
      margin-left: 0;
    }
    
    button {
      width: 100%;
      margin-left: 0;
      margin-top: 40px;
    }

    h2 {
      font-size: 24px;
      text-align: left;
      word-break: keep-all;
    }
  }

  .form-container {
    width: 100%;
    padding: 15px;
  }

}


