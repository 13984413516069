.video-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr); 
    margin-left: 50px; 
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr); 
    margin-left: 25px; 
  }

  @media (max-width: 600px) {
    width: 100%;
    grid-template-columns: 1fr; 
    justify-items: center; 
    margin-left: 0; 
  }
}

.ish-div {
  margin-top: 60px;
}

.ish-title {
  font-size: 40px;
  color: #000929b3;
  font-family: "Unbounded";
  margin-bottom: 30px;
  text-align: center;
}


.video-frame {
  object-fit: cover;
  width: 330px;
  height: 580px; 
  border-radius: 5px;
  max-width: 330px; 
  padding: 45px;
}






@media (max-width: 467px) {
  .video-grid {
    grid-template-columns: 1fr; 
    gap: 20px;
   
  }

  .ish-title {
    font-size: 28px; 
    margin-bottom: 20px; 
  }

  .video-frame {
    max-width: 100%; 
    border-radius: 5px;
    padding: 25px; 
  }
}
