.qanday-section h1 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: bold;
  font-family: 'Unbounded';
  font-size: 40px;
  line-height: 100%;
  font-weight: 700;
  color: rgba(0, 9, 41, 0.7019607843);
  margin-top: 20px;
}

.qanday-div_cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


.qanday-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

.qanday-card {
  width: 250px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.qanday-rows {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.qanday-card h4 {
  font-size: 1.2rem;
}

.qanday-card p {
  font-size: 1rem;
  color: #6c757d;
}


.btn-order {
  padding: 0.75rem 1.5rem;
  background-color: #feb640;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-order:hover {
  background-color: #e4be31;
}




@media (max-width: 768px) {
  .qanday-section h1 {
    font-size: 36px;
    margin-bottom: 1.5rem; 
  }

  .qanday-card {
    width: 220px; 
    padding: 1rem; 
  }

  .qanday-card h4 {
    font-size: 1.1rem; 
  }

  .qanday-card p {
    font-size: 0.9rem; 
  }

  .btn-order {
    padding: 0.6rem 1.2rem; 
    font-size: 0.9rem;
  }
}


@media (max-width: 467px) {
  .qanday-section h1 {
    text-align: left;
    font-size: 28px; 
    margin-bottom: 1rem; 
  }

  .qanday-card {
    width: 100%; 
    padding: 1rem; 
  }

  .qanday-card h4 {
    font-size: 1rem;
  }

  .qanday-card p {
    font-size: 0.85rem; 
    text-align: left;
  }

  .btn-order {
    padding: 0.5rem 1rem; 
    font-size: 0.85rem;
  }

  .qanday-rows{
    justify-content: flex-start;
    word-break: keep-all;
  }
}
