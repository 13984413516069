
.nb {
  background-color: #ffffff;
  padding: 10px 0;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.navbar-logo {
  width: 200px;
  margin-left: 10px;
  margin-top: 10px;
}


.menu-icon {
  display: none;
  font-size: 28px;
  cursor: pointer;
}


.container1 {
  display: flex;
  align-items: center;
  height: 80px;
  justify-content: space-between;
}


.links-nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.navbar-links {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Unbounded';
  padding-top: 10px;
  margin-left: 38px;
  color: #00000098;
  width: 590px;
}

.nav-link {
  text-decoration: none;
  color: #000;
  font-weight: 500;
  padding: 0px 15px;
}

.nav-link:hover {
  color: #feb640;
  border-bottom: 2px solid #feb640;
}


.contact1 {
  margin-left: 30px;
}

.contact-button {
  background-color: #feb640;
  color: #fff;
  border: none;
  padding: 10px 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  border-radius: 20px;
  font-weight: 500;
  font-family: Unbounded;
  width: 180px;
}

.contact-button:hover {
  background-color: #e4be31;
}

.navbar-right {
  display: flex;
  align-items: center;
}


@media (max-width: 768px) {
  .container1 {
    width: 100%;
  }

  .navbar-links {
    display: none;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    text-align: center;
    background-color: #f8f9fa;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 999;
  }

  .navbar-links.open {
    display: flex;
    background-color: #e8eaec;
    height: auto;
  }

  .nav-link {
    padding: 15px 0;
    font-size: 18px;
  }

  .menu-icon {
    display: block;
  }

  .navbar-right {
    margin-top: 10px;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 35px;
  }

  .contact-button {
    width: 100%;
    display: none;
  }
}


@media (max-width: 467px) {
  .navbar-logo {
    width: 140px;
  }

  .nav-link {
    font-size: 16px;
    padding: 10px 0;
  }

  .menu-icon {
    font-size: 24px;
    margin-left: 25px;
  }

  .navbar-right {
    margin-top: 5px;
    gap: 0;
    padding-left: 10px;
  }
  .container1{
    height: 60px;
  }

  .contact-button {
    width: 100%;
    display: none;
  }
}


@media (max-width: 1024px) {
  .navbar-links {
    margin-left: 50px;
    gap: 15px;
    display: none;
  }

  .contact-button {
    padding: 8px 16px;
  }
}


.p-sidebar {
  background-color: #f8f9fa;
  padding: 20px 10px;
  width: 260px;
}

.p-sidebar .nav-link {
  display: block;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
}

.p-sidebar .nav-link:hover {
  color: #007bff;
  background-color: #e8eaec;
  border-radius: 5px;
}


.p-sidebar-footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

.p-sidebar-footer .contact-button {
  width: 400px;
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border-radius: 25px;
  border: none;
  cursor: pointer;
}

.p-sidebar-footer .contact-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .p-sidebar {
    width: 70%;
  }
}
