.faq-div {
  text-align: left;
  word-break: keep-all;
  display: flex;
  flex-direction: row; 

  h4 {
    color: #676d83;
    font-family: 'Unbounded';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    word-break: break-word; 
  }

  p {
    color: #676d83;
    font-family: sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    word-break: break-word; 
  }

  @media (max-width: 1024px) {
    flex-direction: column; 
    align-items: center;
    text-align: left;
    padding: 0 20px;
  }

  @media (max-width: 467px) {
    .faq-div{
      font-size: 15px;
    }
  
  }
}

.faq {
  margin-top: 15px;
  width: 300px;
  font-family: 'Unbounded';

  @media (max-width: 1024px) {
    width: 100%; 
    margin-top: 10px;
  }

  @media (max-width: 467px) {
    width: 100%;
    margin-top: 10px;
    .faq{
    margin-bottom: 25px;
    color: aqua;

    }
  }
}

.faq-accordion {
  margin-top: 25px;
  gap: 15px;
  margin-left: 200px;

  @media (max-width: 1024px) {
    margin-left: 0; 
    width: 100%;    
  }

  @media (max-width: 467px) {
    margin-left: 0;
    width: 100%;    
  }
}
