.cards {
  width: 1200px;
  justify-content: space-between;
  display: flex;
  margin-top: 40px;
  div {
    border-radius: 8px;
    padding: 14px;

    h2 {
     width: 360px;
     max-width: 360px;
      
      color: #020c31;
      font-family: 'Unbounded';
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      text-transform: capitalize;
      margin-bottom: 20px;
     
    }

    p {
      margin-top: 20px;
      width: 100%;
      color: #676d83;
      font-family: 'Unbounded';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
.main-button{
  background-color: #ed9d1d;
  color: #fff;
  border: none;
  padding: 10px 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  border-radius: 20px;
  font-weight: 500;
  font-family: 'Unbounded';
  &:hover{
    background-color: #e4be31;
  }
}
.negabiz {
  font-family: "Unbounded", sans-serif;
  font-optical-sizing: auto;
  color: rgba(0, 9, 41, 0.701960784);
  font-weight: weight;
  font-style: normal;
  
}

.line {
    border: 1px solid;
    font-size: 0.5px;
    
}

.about-backimg {
  background-image: url("../images/carpetman.jpg");
  width: 1200px;
  height: 550px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  border-radius: 16px;
  padding: 40px 70px;
  margin-top: 70px;

  h2 {
    max-width: 558px;
    color: #fafafa;
    font-family: 'Unbounded';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 20px;
  }

  p {
    max-width: 410px;
    color: #fff;
    font-family: 'Unbounded';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 25px;
  }

  a {
    color: #fafafa;
    font-family: 'Unbounded';
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    text-decoration: none;
  }

  .about-btn {
    margin-top: 20px;
  }
}


@media (max-width: 768px) {
  .cards {
    flex-direction: column; 
    width: 100%; 
     
  }

  .cards div {
    width: 100%;
    border: 1px solid #18f1b0b7;
    width: 100%; 
    max-width: 768px; 
    margin-bottom: 20px; 
  }

  .about-backimg {
    width: 100%;
    height: auto; 
    padding: 20px; 
  }

  .about-backimg h2,
  .about-backimg p,
  .about-backimg a {
    text-align: left;
    word-break: break-all; 
    word-wrap: break-word;
    hyphens: auto;
  }
}


@media (max-width: 467px) {
  .cards {
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }

  .cards div {
    max-width: 100%; 
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #18f1b0b7; 
  }

  .cards div h2 {
    width: 100%;
    line-height: 28px;
  }

  .cards div p {
    font-size: 14px;
    line-height: 20px;
    width: 100%; 
  }

  .about-backimg {
    width: 100%; 
    padding: 15px;
    margin-top: 40px;
  }

  .about-backimg h2 {
    font-size: 28px; 
    line-height: 36px;
  }

  .about-backimg p {
    font-size: 14px;
    line-height: 20px;
    max-width: 100%; 
  }

  .about-backimg a {
    font-size: 24px; 
  }

  .about-btn {
    margin-top: 15px;
  }
}
