.ish-container_div {
  display: flex;
}

.oxirgi-title {
  margin: 30px 0px;
}

.oxirgi-title h1 {
  color: #020c31;
  font-family: Unbounded;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
}

.damas-container {
  position: relative;
  width: 650px;
  overflow: hidden;
  margin-right: 15px;
}

.damas-image {
  width: 100%;
  height: 710px;
  transition: transform 0.3s ease;
  border-radius: 10px;
}

.damas-image:hover {
  transform: scale(1.2);
}

.damas-container:hover .damas-image {
  transform: scale(1.2);
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.image-container {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin-right: 15px;
}

.image {
  width: 100%;
  height: 350px;
  transition: transform 0.3s ease;
}

.image-container:hover .image {
  transform: scale(1.2);
  filter: contrast(120%);
}

.centered-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #feb640;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.image-container:hover .centered-button,
.damas-container:hover .centered-button {
  opacity: 1;
}

.centered-button:hover {
  background-color: #e4be31;
}

@media (max-width: 768px) {
  .ish-container_div {
    flex-direction: column;
    align-items: center;
  }

  .damas-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .image-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .image {
    height: auto;
  }

  .damas-image {
    height: auto;
  }
}

@media (max-width: 467px) {
  .oxirgi-title h1 {
    font-size: 28px;
  }

  .damas-container {
    width: 100%;
  }

  .image-grid {
    grid-template-columns: 1fr;
  }

  .damas-image,
  .image {
    height: auto;
  }

  .centered-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}
