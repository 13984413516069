
.comment-section {
  padding: 30px 0;
}

.swiper-base {
  width: 377px;
  height: 256px;
  border: 1px solid;
}


.comenth1 {
  text-align: center;
  font-size: 36px;
  font-family: 'Unbounded';
  color: rgba(0, 9, 41, 0.7019607843);
  margin-bottom: 40px;
}




.swiper-wrapper {
  display: flex;
}


.testimonial-card {
  width: 367px !important;
  height: 256px;
  background-color: white;
  border: 0.5px solid #04A2BA;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}


.comment-image_container {
  display: flex;
}

.comment-image_container img {
  border-radius: 50%;
}

.comment-image_container span {
  margin-left: 15px;
}

.comment-image_container h2 {
  margin-left: 15px;
}


.testimonial-card h2 {
  font-size: 20px;
  color: #00796b;
  margin-bottom: 10px;
  font-family: 'Unbounded';
}

.testimonial-card p {
  margin-top: 15px;
  font-size: 12px;
  color: #555;
  line-height: 1.5;
  margin-bottom: 10px;
  font-family: 'Unbounded';
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}


.swiper-pagination-bullet {
  background: #00796b !important;
}

.swiper-pagination-bullet-active {
  background: #004d40 !important;
}

@media (max-width: 768px) {
  .testimonial-card {
    width: 350px !important;
    padding: 15px;
 
  }

  h1 {
    font-size: 28px;
  }

  .swiper-wrapper {
    flex-direction: column;
  }

  .testimonial-card h2 {
    font-size: 20px;
  }

  .testimonial-card p {
    font-size: 14px;
  }

  .swiper-slide {
    width: 100% !important;
  }
}

@media (max-width: 467px) {
  .testimonial-card {
  width: 100% !important;
    height: 250px;
  }

  .swpdiv{
    width: 100%;
    padding: 5px;
  }

  h1 {
    font-size: 24px;
  }

  .swiper-wrapper {
    flex-direction: column;
  }

  .testimonial-card h2 {
    font-size: 18px;
  }

  .testimonial-card p {
    font-size: 12px;
  }

  .swiper-slide {
    width: 100% !important;
  }
}
