@import "navbar";
@import "maon";
@import "whyus";
@import "servicetype";
@import "sliderchange";
@import "workprocess";
@import 'howprocesswork';
@import 'projectsworkplace';
@import 'comments';
@import 'faq';
@import 'contact';
@import 'footer';
@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&display=swap");


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  text-align: left;
  word-break: break-all;
  overflow-x: hidden;
  overflow-y: hidden;
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;

}

li{
  list-style-type: none;
}



