.footer-ul {
    display: flex;
    height: 276px;
    flex-wrap: wrap; 
    color: #242825cc;
    font-family: sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  
    ul {
      margin: 50px 50px; 

      li{
        margin-top: 15px;
      }
    }
  
    a {
      text-decoration: none;
      color: #242825cc;
    font-family: sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    }
  
    img {
      margin: 50px 50px;
      width: 200px;
    }
  }
  

  @media (max-width: 768px) {
    .footer-ul {
        display: block;
      flex-direction: column;
      align-items: center; 
      height: auto; 
  
      ul {
        margin: 20px 0;
      }
  
      img {
         
       margin: 20px 0px 0px 0px;
      }
    }
  }
  

  @media (max-width: 467px) {


    .footer-div{
      overflow: hidden;
    }
    .footer-ul {
        display: block;
      align-items: center; 
      height: auto; 
  
      ul {
        display: block;
        margin: 10px 0; 
      }
  
      img {
        width: 140px; 
        margin: 10px 0; 
      }
    }
  }
  