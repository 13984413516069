#main {
  padding: 20px 0;
  
  .main-div {
    font-family: "Unbounded", sans-serif;
    font-optical-sizing: auto;
    font-weight: weight;
    font-style: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    flex-wrap: wrap; 
  }

  .text-content1 {
    flex: 1;
    max-width: 45 0px;

    h1 {
      font-size: 70px;
      line-height: 70px;
      min-height: 0;
      color: #feb640;
      font-family: 'Unbounded', sans-serif;
      font-weight: 500;

    }

    p {
      max-width: 455px;
      font-family: 'Unbounded',sans-serif;
      font-size: 20px;
      font-weight: 400;
      font-style: normal;
      word-break: auto-phrase;
      color: #272121;
      margin-top: 70px;
      margin-bottom: 25px;
    }
  }

  .main-button {
    background-color: #ed9d1d; 
    color: #fff;
    border: none;
    padding: 12px 30px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 20px;
    transition: background-color 0.3s ease;
    font-weight: 500;
    font-family: 'Unbounded';

    &:hover {
      background-color: #e4be31;
    }
  }

  .image-container {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .main-img {
    max-width: auto;
    height: auto;
    border-radius: 10px;
  }


  @media (max-width: 768px) {
    .main-div {
      flex-direction: column; 
      text-align: center; 
      background-image: url("../images/manwithcarpet.png"); 
      background-size: cover; 
      background-position: center; 
      height: 400px; 
      border-radius: 10px;
      padding: 15px;
    }

    .text-content1 {
        width: 100%;

      h1 {
        width: 100%;
        font-size: 40px;
        color: #ffffff;
      }

      p {
        text-align: left;
        word-break: break-all;
        width: 400px;
        color: #ffffff;
      }
    }

    .main-button {
    margin-top: 15px;
    margin-right: 475px;
    min-width: 180px;
    }

    .main-img {
      display: none; 
    }

    .main-mini_img {
      display: none;
    }
  }


  @media (max-width: 467px) {
    .main-div {
      height: 300px;
        }

    .text-content1 {
      
      width: 100%;
      word-break: break-all;



      h1 {
        font-size: 30px;
        margin-top: 5px;
        line-height: 35px;
     
        width: 100%;
        text-align: left;
        word-break: keep-all;
        color: #ffffff;
        height: auto;
      }

      p {
        word-break: keep-all;
        font-size: 18px;
        margin-top: 7px;
        width: 100%;
        height: 120px;
        
      }

      button {
        margin-right: 166px;
        min-width: 160px;
        font-size: 14px;
      }
    }

    .main-mini_img {
      display: none;
    }
  }
}
